import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./services/utils/configureStore";
import { history } from "./services/utils/history";
import Spinner from "./components/loadingIndicator/Spinner";
import styled from "./services/utils/styled";
import ViewPortProvider from "./providers/ViewPortProvider";
import { LazyLoadingHelper } from "./helpers/LazyLoadingHelper";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const initialState = {};
const store = configureStore(initialState, history);

const App = lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./App")));
const InterruptedConnectionNotification = lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./services/notification/InterruptedConnectionNotification")));
const DownloadNotification = lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./services/notification/DownloadNotification")));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <ViewPortProvider>
            <React.StrictMode>
                <BrowserRouter>
                    <Suspense fallback={<Wrapper><Spinner /></Wrapper>}>
                        <App />
                        <InterruptedConnectionNotification />
                        <DownloadNotification />
                    </Suspense>
                </BrowserRouter>
            </React.StrictMode>
        </ViewPortProvider>
    </Provider>
);
